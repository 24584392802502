import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import logo from '../../static/logos/icon.png'

const Header = styled.header`
  background: #fff;
  width: 100%;
  position: fixed;
  z-index: 2;
  border-bottom: #f8f8f9 solid 1px;
  height: 70px;
  margin-top: -70px;
  display: flex;
  align-items: center;
`
const Nav = styled.nav`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 1.5em;

  ul {
    display: flex;
    justify-content: space-between;
  }

  li {
    display: inline-block;
    margin-left: 1em;    
    margin-top:.7rem;
    &:first-child {
      position: relative;
      margin: 0;
      flex-basis: 100%;
      font-weight: 600;
      font-size: 1.7rem;
    }
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.base};    
    transition: all 0.2s;
    // border-bottom: 2px solid ${props => props.theme.colors.base};
    &:hover {
      color: black;
    }
  }
  .logo {
    height:36px;
    width:36px;
    margin-right:10px;
  }
  .home{
    display:flex;
  }
  .spiffy{
    padding-top:5px;
  }
`

const activeLinkStyle = {
  color: '#121212',
}

const Menu = () => {
  return (
    <Header>
      <Nav>
        <ul>
          <li>
            <Link to="/" activeStyle={activeLinkStyle} className="home">
              <img src={logo} alt="Logo" className="logo" />
              <div className="spiffy">Spiffy Biro</div>
            </Link>
          </li>
          <li>
            <Link to="/about/" activeStyle={activeLinkStyle}>
              About
            </Link>
          </li>
          {/* <li>
            <Link to="/contact/" activeStyle={activeLinkStyle}>
              Contact
            </Link>
          </li> */}
        </ul>
      </Nav>
    </Header>
  )
}

export default Menu
